"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildDelegatorLabel = exports.buildParticipantLabel = void 0;
const shortString = (value) => {
    const length = value.length;
    if (length <= 12)
        return value;
    return value.slice(0, 6) + "..." + value.slice(length - 4, length);
};
const buildParticipantLabel = (participant) => {
    return (shortString(participant.address));
};
exports.buildParticipantLabel = buildParticipantLabel;
const buildDelegatorLabel = (delegator) => {
    if (delegator.choice != 0)
        return (0, exports.buildParticipantLabel)(delegator);
    return `${shortString(delegator.address)} (${delegator.space})`;
};
exports.buildDelegatorLabel = buildDelegatorLabel;

"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.loadProposal = void 0;
const snapshot_1 = require("./snapshot");
const PROPOSAL_QUERY = `query Proposal($id: String!) {
    proposal(id: $id) {
        id
        ipfs
        title
        body
        discussion
        choices
        start
        end
        snapshot
        state
        author
        created
        plugins
        network
        type
        quorum
        symbol
        privacy
        strategies {
            name
            network
            params
        }
        space {
            id
            name
        }
        scores_state
        scores
        scores_by_strategy
        scores_total
        votes
    }
}
`;
const loadProposal = (id) => __awaiter(void 0, void 0, void 0, function* () {
    return (yield (0, snapshot_1.queryData)("Proposal", PROPOSAL_QUERY, {
        id
    })).proposal;
});
exports.loadProposal = loadProposal;

"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.loadDelegates = void 0;
const address_1 = require("@ethersproject/address");
const snapshot_1 = require("./snapshot");
const DELEGATIONS_QUERY = `query Delegates($first: Int, $skip: Int, $delegates: [String], $spaces: [String]){ 
    delegations (
      first: $first
      skip: $skip
      where: {delegate_in: $delegates, space_in: $spaces}
    ) { 
      delegator 
      delegate
      space
    }
  }`;
const loadDelegatesBatch = (delegates, spaces) => __awaiter(void 0, void 0, void 0, function* () {
    const delegations = [];
    var offset = 0;
    while (true) {
        const delegatesResp = (yield (0, snapshot_1.queryData)("Delegates", DELEGATIONS_QUERY, {
            delegates,
            spaces,
            first: snapshot_1.PAGE_SIZE,
            skip: offset,
        }, snapshot_1.SNAPSHOT_SUBGRAPH_MAINNET)).delegations;
        delegations.push(...delegatesResp);
        if (delegatesResp.length != snapshot_1.PAGE_SIZE)
            break;
        offset += snapshot_1.PAGE_SIZE;
    }
    return delegations;
});
const addDelegate = (delegates, vote, delegation) => {
    const delegateAddress = (0, address_1.getAddress)(delegation.delegate);
    if (!delegates.has(delegateAddress)) {
        delegates.set(delegateAddress, []);
    }
    const delegate = delegates.get(delegateAddress);
    delegate.push({
        address: (vote === null || vote === void 0 ? void 0 : vote.address) || (0, address_1.getAddress)(delegation.delegator),
        choice: (vote === null || vote === void 0 ? void 0 : vote.choice) || 0,
        votingPower: (vote === null || vote === void 0 ? void 0 : vote.votingPower) || 0,
        hasDelegates: (vote === null || vote === void 0 ? void 0 : vote.hasDelegates) || false,
        space: delegation.space,
        created: (vote === null || vote === void 0 ? void 0 : vote.created) || 0,
    });
};
const higherPriorityDelegation = (left, right) => {
    if (!right)
        return left;
    // Naive assumption -> safe.eth > safe > ""
    if (left.space.length > right.space.length)
        return left;
    return right;
};
const loadDelegateChunk = (chunk, delegators, spaces) => __awaiter(void 0, void 0, void 0, function* () {
    const delegations = yield loadDelegatesBatch(chunk, spaces);
    delegations.forEach((delegation) => {
        const current = delegators.get(delegation.delegator);
        delegators.set(delegation.delegator, higherPriorityDelegation(delegation, current));
    });
});
const loadDelegates = (votes, space) => __awaiter(void 0, void 0, void 0, function* () {
    const delegators = new Map();
    var currentChunk = [];
    const spaces = ["", space];
    if (space.endsWith(".eth")) {
        spaces.push(space.replace(".eth", ""));
    }
    for (const vote of votes.values()) {
        if (!vote.hasDelegates)
            continue;
        currentChunk.push(vote.address);
        if (currentChunk.length < 500)
            continue;
        console.log("Load Chunk");
        yield loadDelegateChunk(currentChunk, delegators, spaces);
        currentChunk = [];
    }
    if (currentChunk.length > 0) {
        console.log("Load Last Chunk");
        yield loadDelegateChunk(currentChunk, delegators, spaces);
    }
    const delegates = new Map();
    delegators.forEach((delegation) => {
        const vote = votes.get((0, address_1.getAddress)(delegation.delegator));
        addDelegate(delegates, vote, delegation);
    });
    return delegates;
});
exports.loadDelegates = loadDelegates;

"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.loadVotes = void 0;
const snapshot_1 = require("./snapshot");
const address_1 = require("@ethersproject/address");
const VOTE_QUERY = `query Votes($id: String!, $first: Int, $skip: Int, $orderBy: String, $orderDirection: OrderDirection, $voter: String) {
    votes(
      first: $first
      skip: $skip
      where: {proposal: $id, vp_gt: 0, voter: $voter}
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      ipfs
      voter
      choice
      vp
      vp_by_strategy
      reason
      created
    }
  }`;
const loadVotes = (id) => __awaiter(void 0, void 0, void 0, function* () {
    const votes = new Map();
    var offset = 0;
    while (true) {
        const votesResp = (yield (0, snapshot_1.queryData)("Votes", VOTE_QUERY, {
            id,
            orderBy: "vp",
            orderDirection: "desc",
            first: snapshot_1.PAGE_SIZE,
            skip: offset,
        })).votes;
        votesResp.forEach((vote) => {
            const voterAddress = (0, address_1.getAddress)(vote.voter);
            votes.set(voterAddress, {
                address: voterAddress,
                votingPower: vote.vp,
                choice: vote.choice,
                hasDelegates: vote.vp_by_strategy[1] > 0,
                created: vote.created
            });
        });
        if (votesResp.length != snapshot_1.PAGE_SIZE)
            break;
        offset += snapshot_1.PAGE_SIZE;
    }
    return votes;
});
exports.loadVotes = loadVotes;

"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.renderTimeline = exports.renderVoteDistribution = void 0;
const network_1 = require("./network");
const timeline_1 = require("./timeline");
const showError = (status) => __awaiter(void 0, void 0, void 0, function* () {
    const loadingContainer = document.getElementById("loading-container");
    loadingContainer.textContent = status;
});
const renderVoteDistribution = () => {
    var url = new URL(window.location.href);
    const proposalId = url.searchParams.get("proposal");
    console.log({ proposalId });
    (0, network_1.buildGraph)(proposalId).catch(() => {
        showError("Cannot process proposal: " + proposalId);
    });
};
exports.renderVoteDistribution = renderVoteDistribution;
const renderTimeline = () => {
    var url = new URL(window.location.href);
    const proposalId = url.searchParams.get("proposal");
    console.log({ proposalId });
    (0, timeline_1.buildTimeline)(proposalId).catch((e) => {
        console.log(e);
    });
};
exports.renderTimeline = renderTimeline;
